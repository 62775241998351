const problemFilters = [
  {
    label: "Лесно",
    color: "success",
  },
  {
    label: "Средно",
    color: "warning",
  },
  {
    label: "Трудно",
    color: "error",
  },
];

export default problemFilters;
