const globalChannels = [
  {
    name: "welcome",
  },
  {
    name: "suggestions",
  },
  {
    name: "general",
  },
  {
    name: "off-topic",
  },
  {
    name: "coding-problems",
  },
];

export default globalChannels;
